import { GET_MODULE_LEVEL_ACCESS } from "config/api";
import axiosInstance from "Utils/axios";
import axiosPlatformInstance from "Utils/axiosPlatform";
import {
  DATAPLATFORM_DATA_TRANSFER_GET_DYNAMIC_FIELDS,
  DATAPLATFORM_DATA_TRANSFER_GET_JOB_LIST,
  DATAPLATFORM_DATA_TRANSFER_GET_TOTAL_JOB_COUNT,
  DATAPLATFORM_DATA_TRANSFER_SUBMIT_ENDPOINT,
} from "../constants-dataIngestion/apiConstants";

//module access
export const getModuleLevelAccess = ( app, module ) => () => {
  const queryParam = {
    app,
    module,
  };
  return axiosInstance({
    url: `${GET_MODULE_LEVEL_ACCESS}`,
    params: queryParam,
    method: "GET",
  });
};

// Data Transfer
export const saveDataTransferFormDetails = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_SUBMIT_ENDPOINT}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const getDataTransferFormDynamicFields = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_GET_DYNAMIC_FIELDS}`,
    method: "POST",
    data: reqBody,
  });
  return data;
};

export const getTotalJobCount = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_GET_TOTAL_JOB_COUNT}`,
    method: "GET"
  });
  return data;
};

export const getJobList = (reqBody) => async () => {
  const { data } = await axiosPlatformInstance({
    url: `${DATAPLATFORM_DATA_TRANSFER_GET_JOB_LIST}`,
    method: "POST",
    data: reqBody,
  });
  return data;
}