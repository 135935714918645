import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import {
  getDropdownValues,
  setHelpDesk,
} from "../../actions/tenantConfigActions";
import {
  setNotifications,
  setNotificationDND,
  setWebSocketClient,
} from "../../actions/notificationActions";
import AppBar from "@mui/material/AppBar";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Notification from "../../pages/notifications/components/notifications-model";
import Popover from "@mui/material/Popover";
import { firebaseobj } from "../../commonComponents/auth/firebase";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { TENANT, WEBSOCKET_URL } from "../../config/api";
import { Badge, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import globalStyles from "Styles/globalStyles";
import { setActiveUserApp } from "actions/sideBarActions";
import { DATAPLATFORM_DATA_TRANSFER, DATAPLATFORM_DATA_TRANSFER_GET_JOB_LIST, DATAPLATFORM_DATA_TRANSFER_JOBS } from "modules/dataIngestion/constants-dataIngestion/apiConstants";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.common.white,
    border: "none",
    borderRadius: "0",
    boxShadow: "0px 1px 4px #00000029",
    padding: "0.75rem 1.5rem",
    position: "sticky",
    right: 0,
    zIndex: 800,
    top: 0,
    height: theme.customVariables.closedNavWidth,
  },
  navList: {
    margin: 0,
    gap: "0.375rem",
  },
  heading: {
    textDecoration: "none",
    color: theme.palette.textColours.codGray,
  },
  profile: {
    background: theme.palette.background.primary,
    color: theme.palette.textColours.gray,
    height: "3rem",
    marginRight: "1rem",
    width: "3rem",
  },
  userText: {
    alignSelf: "center",
    color: theme.palette.textColours.codGray,
    fontWeight: "normal",
  },
  headerIcons: {
    color: theme.palette.primary.main,
  },
  notificationModel: {
    "& .MuiPopover-paper": {
      minWidth: "26rem",
      width: "30rem",
      minHeight: "30rem",
    },
  },
  notificationIcon: {
    marginRight: "0.5rem",
  }
}));
function Header(props) {
  const [unreadCount, setUnreadCount] = useState(0);
  const [dnd, setDnd] = useState(false);

  const [notifications, setNotificationsData] = useState(
    props.notificationData
  );
  let enableNotification = [DATAPLATFORM_DATA_TRANSFER, DATAPLATFORM_DATA_TRANSFER_JOBS] 
  let userName = localStorage.getItem("name")
    ? localStorage.getItem("name").split("@")[0]
    : "User";
  const shortName = userName
    .split(".")
    .map((item) => item[0].toUpperCase())
    .join("");
  userName = userName
    .split(".")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(" ");
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [webSocketClient, setWsClient] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const connectWebsocket = async () => {
    const user = firebaseobj.auth().currentUser;
    let token = await user.getIdToken();
    props.websocket.onopen = () => {
    props.websocket.send(
            JSON.stringify({ tenant: TENANT, token: token})
          );
    };

    /**
     * @desc Callback function to handle data when recieved through WebScoket 
     * @param {Object} message 
     */
    props.websocket.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      if (dataFromServer.data) {
        if (!dataFromServer.page) {
          props.setNotificationDND(dataFromServer.data.dnd);
        } else {
          // Update the latest recieved notifications
          let updateNotifications = {
            notifications: dataFromServer.data.notifications,
            unread_count: dataFromServer.data.unread_count,
            dnd: dataFromServer.data?.dnd,
            total: dataFromServer.total,
          };
          props.setNotifications(updateNotifications);
          setNotificationsData(updateNotifications.notifications);
        }
      }

      if (dataFromServer.unread_count || dataFromServer.unread_count === 0) {
        setUnreadCount(dataFromServer.unread_count);
      }
    };
    props.websocket.onclose = () => {
      // convert date to a string in UTC timezone format:
      // connectWebsocket();
    };
    props.websocket.onerror = function () {
      console.log("Websocket Connection Error");
    };
    setWsClient(props.websocket);
  };

  useEffect(async () => {
    connectWebsocket()
    const getHelpDesk = async () => {
      let helpDeskResponse = await props.getDropdownValues(2, {
        attribute_name: "help_desk_url",
      });
      props.setHelpDesk(
        helpDeskResponse?.data?.data?.[0].attribute_value?.value
      );
    };
    // getHelpDesk();
  }, [props.websocket]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setUnreadCount(props.notificationUnreadCount);
  }, [props.notificationUnreadCount]);

  useEffect(() => {
    setDnd(props.dnd);
  }, [props.dnd]);
  useEffect(() => {
    setNotificationsData(props.notificationData);
  }, [props.notificationData]);

  const openSupportLinkInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <AppBar position="fixed" className={classes.header}>
      <div
        className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter}`}
      >
        <Link
          to="/home"
          className={classes.heading}
        >
          <Typography component="h1" variant="h3">
            {props.title}
          </Typography>
        </Link>
        <ul
          className={`${globalClasses.flexRow} ${globalClasses.layoutAlignCenter} ${classes.navList}`}
        >
          {/* {
            <li title="Raise support ticket">
              <IconButton
                aria-label="raise support ticket"
                onClick={() => openSupportLinkInNewTab(props.helpDeskUrl)}
              >
                <HelpOutlineIcon className={classes.headerIcons} />
              </IconButton>
            </li>
          } */}
          {enableNotification.includes(window.location.pathname) && <li title="Notification">
            <IconButton
              aria-label="notification"
              className={classes.notificationIcon}
              onClick={handleClick}
              size="large"
            >
              <Badge badgeContent={dnd ? null : unreadCount} color="error">
                <NotificationsIcon className={classes.headerIcons} />
              </Badge>
            </IconButton>
          </li>}
          <li
            className={`${globalClasses.flexRow} ${globalClasses.layoutAlignCenter}}`}
          >
            <Avatar className={classes.profile} color="primary">
              {shortName}
            </Avatar>
            <Typography
              className={classes.userText}
              component="span"
              variant="h4"
              title={userName}
            >
              {" "}
              Hi, {userName}
            </Typography>
          </li>
          <Popover
            id="simple-menu"
            anchorEl={anchorEl}
            placement="bottom"
            className={classes.notificationModel}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Notification
              characterLimit={true}
              {...props}
              onClose={handleClose}
              websocket={webSocketClient}
            ></Notification>
          </Popover>
        </ul>
      </div>
    </AppBar>
  );
}

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notificationUnreadCount: state.notificationReducer.notificationUnreadCount,
  dnd: state.notificationReducer.dnd,
  notificationData: state.notificationReducer.notificationData,
  helpDeskUrl: state.tenantConfigReducer.helpDesk || "",
  websocket: state.notificationReducer.wsClient,
});

export default connect(mapStateToProps, {
  logoutUser,
  setNotificationDND,
  setNotifications,
  setWebSocketClient,
  getDropdownValues,
  setActiveUserApp,
  setHelpDesk,
})(withRouter(Header));
