//api constants

// Tenanat config API URLs
export const DATAINGESTION_TENANT_CONFIG_GET_ALL = "data-platform/configuration/sourcing_configuration";
export const INGESTION_CONFIG_GET_ALL = "data-platform/configuration/ingestion_configuration";
export const DATAPLATFORM_SOURCING_CONFIG = "/data-platform/sourcing"
export const DATAPLATFORM_SOURCING_TRIGGERS_CONFIG = "/data-platform/generic_trigger_mapping"
export const DATAPLATFORM_CONFIG = "/data-platform/data-ingestion";
export const DATAPLATFORM_INGESTION_CONFIG = "/data-platform/configuration"
export const DATAPLATFORM_GENERIC_MAPPING_TABLE_CONFIG = "/data-platform/table-list";
export const DATAPLATFORM_DERIVED_TABLE = "/data-platform/derived-table";
export const DATAPLATFORM_TABLE_SCHEMA = "/data-platform/table-schemas";

export const DATAPLATFORM_SOURCING_MAPPING_CONFIG = "/data-platform/source_mapping";
export const DATAPLATFORM_GENERIC_MASTER_MAPPING = "/data-platform/generic_master_mapping";
export const DATAPLATFORM_GENERIC_SCHEMA_MAPPING = "/data-platform/generic_schema_mapping";
export const DATAPLATFORM_GENERIC_MASTER_MAPPING_TABLE_LIST = "/data-platform/generic_master_mapping/mapping";
export const DATAPLATFORM_DERIVED_TABLE_MAPPING = "/data-platform/derived_tables_mapping";

export const DATAPLATFORM_SOURCING_BULK_VALIDATION = "/data-platform/validation";
export const DATAPLATFORM_INGESTION_BULK_VALIDATION = "/data-platform/ingestion_validation";
export const DATAPLATFORM_INGESTION_QUERY_VALIDATION = "/data-platform/ingestion_validation/attributes";
export const DATAPLATFORM_SOURCING_QUERY_VALIDATION = "/data-platform/validation/attributes";
export const DATAPLATFORM_SOURCING_MAPPING_BULK_VALIDATION = "/data-platform/validation/generic_master_mapping";
export const DATAPLATFORM_INGESTION_QUERY_GENERATOR = "data-platform/configuration/ingestion_configuration/query_generator/";

export const DATAINGESTION_QC_MODULE_KPI_MASTER_GET_ALL = "data-platform/qc_module/kpi_master";
export const DATAINGESTION_QC_MODULE_RULE_MASTER_GET_ALL = "data-platform/qc_module/rule_master";
export const DATAINGESTION_QC_MODULE_CUSTOM_QUERY_GET_ALL = "data-platform/qc_module/fetch_custom_qc";
export const DATAINGESTION_QC_MODULE_CUSTOM_QUERY_GET_TABLE_LIST = "data-platform/qc_module/fetch_table_info";
export const DATAINGESTION_QC_MODULE = "/data-platform/qc_module"

export const DATAPLATFORM_DATA_TRANSFER = "/data-platform/data-transfer";
export const DATAPLATFORM_DATA_TRANSFER_JOBS = "/data-platform/jobs";
export const DATAPLATFORM_DATA_TRANSFER_SUBMIT_ENDPOINT = "/data-platform/etl/submit-job";
export const DATAPLATFORM_DATA_TRANSFER_GET_DYNAMIC_FIELDS = "/data-platform/etl/get-pipeline-options";
export const DATAPLATFORM_DATA_TRANSFER_GET_TOTAL_JOB_COUNT = "/data-platform/etl/get-total-job-count";
export const DATAPLATFORM_DATA_TRANSFER_GET_JOB_LIST = "/data-platform/etl/get-all-jobs";
