import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Container } from "@mui/material";
import globalStyles from "Styles/globalStyles";
import DataIngestionConfigTable from "./dataIngestionConfigTable";
import SourceMappingConfigTable from "./sourceMappingConfigTable";
import {
  DATAPLATFORM_SOURCING_BULK_VALIDATION,
  DATAPLATFORM_SOURCING_QUERY_VALIDATION,
} from "modules/dataIngestion/constants-dataIngestion/apiConstants";
import BulkValidation from "./BulkValidation";
import AddSourceMappingConfig from "./AddSourceMappingConfig";
import { addSnack } from "../../../../actions/snackbarActions";
import { createMappingTableConfig } from "../../../../actions/tenantConfigActions";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { CREATE, SOURCING_CONFIGURATION_MODULE, SOURCING_MAPPING_MODULE } from "modules/dataIngestion/constants-dataIngestion/moduleConstants";
import { getModuleLevelAccess } from "modules/dataIngestion/services-dataIngestion/dataIngestion-combined-service";

const Header = (props) => {
  const globalClasses = globalStyles();
  return (
    <div
      className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
    >
      <Typography variant="h4">Configurations</Typography>
      {props.configurationTab !== "data_ingestion_configuration"  ? (
        props.userRole && props.userRole[CREATE] && <Button
          variant="contained"
          color="primary"
          onClick={() => props.onClickAddConfigHandler(true)}
          // startIcon={<AddIcon />}
        >
          Add Row
        </Button>
      ) : props.userRole && props.userRole[CREATE] && <span> <Button
          id="bulkValidate"
          variant="contained"
          color="primary"
          onClick={props.onClickQueryandler}
          style={{ marginTop: "1rem" }}
        >
          Intermediate Queries Validator 
        </Button>
        <Button
            id="bulkValidate"
            variant="contained"
            color="primary"
            onClick={props.onClickBulkHandler}
            style={{ marginTop: "1rem",marginLeft: "1rem" }}
          >
            Bulk Validate
          </Button>
          </span>
        
      }
    </div>
  );
};
const Configuration = (props) => {
  let [showBulkValidationModal, setShowBulkValidationModal] = useState(false);
  let [showAddConfigModal, setShowAddConfigModal] = useState(false);
  const [renderAgGrid, setRenderAgGrid] = useState(true);
  const [renderMappingAgGrid, setRenderMappingAgGrid] = useState(true);
  let [ showIntermediateQueryValidationModal, setShowIntermediateQueryValidationModal ] = useState(false);
  let [ queryPayload, setQueryPayload ] = useState([]);
  let [ userScreenLevelAccess, setUserScreenLevelAccess ] = useState({})

 useEffect(async()=>{
    const userAccessData = await props.getModuleLevelAccess("dataingestion",SOURCING_CONFIGURATION_MODULE)
    setUserScreenLevelAccess(userAccessData.data.data)
  },[])
  
  
  useEffect(()=>{
    if(!renderMappingAgGrid){
      setRenderMappingAgGrid(true)
    }
  },[renderMappingAgGrid])

  const newFormFields = [
    {
      fc_code: 280,
      label: "Tenant",
      column_name: "tenant",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 1,
      is_required: true,
      extra: {
        helperText: "Enter a valid tenant name",
      },
    },
    {
      fc_code: 281,
      label: "Table",
      column_name: "table",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 2,
      is_required: true,
      extra: {
        helperText: "Enter a valid table name",
      },
    },
    {
      fc_code: 282,
      label: "View",
      column_name: "view",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 3,
      is_required: true,
      extra: {
        helperText: "Enter a valid view name",
      },
    },
    {
      fc_code: 283,
      label: "Connector",
      column_name: "connector",
      type: "non-cascaded",
      display_type: "dropdown",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 4,
      is_required: true,
      options: [
        { label: "GCS", value: "GCS" },
        { label: "MSSQL", value: "MSSQL" },
        { label: "SFTP", value: "SFTP" },
        { label: "Snowflake", value: "Snowflake" },
      ],
      extra: {
        helperText: "Enter a valid connector name",
      },
    },
    {
      fc_code: 284,
      label: "Source config",
      column_name: "source_config",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 5,
      is_required: true,
      extra: {
        helperText: "Enter a valid source config name",
      },
    },
    {
      fc_code: 285,
      label: "Pull Type",
      column_name: "pull_type",
      type: "non-cascaded",
      display_type: "dropdown",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 6,
      is_required: true,
      options: [
        { label: "incremental", value: "incremental" },
        { label: "full", value: "full" },
      ],
      extra: {
        helperText: "Select a valid pull type name",
      },
    },
    {
      fc_code: 287,
      label: "Schedule Interval",
      column_name: "schedule_interval",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 8,
      is_required: true,
      extra: {
        helperText: "Enter a valid schedule interval name",
      },
    },
    {
      fc_code: 288,
      label: "Intermediate Table",
      column_name: "intermediate_table",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 9,
      is_required: true,
      extra: {
        helperText: "Enter a valid intermediate table name",
      },
    },
    {
      fc_code: 289,
      label: "Filter",
      column_name: "filter",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 10,
      is_required: true,
      extra: {
        helperText: "Enter a valid filter name",
      },
    },
    {
      fc_code: 290,
      label: "Dataingestion Filterparam",
      column_name: "dataingestion_filterparam",
      type: "non-cascaded",
      display_type: "DateTimeField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 11,
      is_required: true,
      extra: {
        helperText: "Enter a valid dataingestion filter param name",
      },
    },
    {
      fc_code: 291,
      label: "Filter Param",
      column_name: "filter_param",
      type: "non-cascaded",
      display_type: "DateTimeField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 12,
      is_required: true,
      extra: {
        helperText: "Enter a valid filter param name",
      },
    },
    {
      fc_code: 292,
      label: "Extraction sync date Interval",
      column_name: "extraction_sync_dt",
      type: "non-cascaded",
      display_type: "DateTimeField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 13,
      is_required: true,
      extra: {
        helperText: "Enter a valid extraction sync date interval name",
      },
    },
    {
      fc_code: 293,
      label: "Source Format Regex",
      column_name: "source_format_regex",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 14,
      is_required: true,
      extra: {
        helperText: "Enter a valid source format regex name",
      },
    },
    {
      fc_code: 295,
      label: "Partition Column",
      column_name: "partition_column",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 16,
      is_required: true,
      extra: {
        helperText: "Enter a valid partition_column name",
      },
    },
    {
      fc_code: 296,
      label: "Clustering Columns",
      column_name: "clustering_columns",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 17,
      is_required: true,
      extra: {
        helperText: "Enter a valid clustering columns name",
      },
    },
    {
      fc_code: 297,
      label: "Query Partitioning Threshold",
      column_name: "query_partitioning_threshold",
      type: "non-cascaded",
      display_type: "IntegerField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 18,
      is_required: true,
      extra: {
        helperText: "Enter a valid query partitioning threshold name",
      },
    },
    {
      fc_code: 294,
      label: "Inter query exec order",
      column_name: "inter_query_exec_order",
      type: "non-cascaded",
      display_type: "IntegerField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 15,
      is_required: true,
      extra: {
        helperText: "Enter a valid inter query exec order number",
      },
    },
    {
      fc_code: 298,
      label: "Num Partitions",
      column_name: "num_partitions",
      type: "non-cascaded",
      display_type: "IntegerField",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 19,
      is_required: false,
      extra: {
        helperText: "Enter a valid num partitions name",
      },
    },
    {
      fc_code: 299,
      label: "Db Partition Column",
      column_name: "db_partition_column",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 20,
      is_required: false,
      extra: {
        helperText: "Enter a valid db partition column name",
      },
    },
    {
      fc_code: 300,
      label: "Field Delimiter",
      column_name: "field_delimiter",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 21,
      is_required: true,
      extra: {
        helperText: "Enter a valid field delimiter",
      },
    },
    {
      fc_code: 286,
      label: "Replace",
      column_name: "replace",
      type: "non-cascaded",
      display_type: "ToogleField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 7,
      is_required: true,
      extra: {
        helperText: "Enter a valid replace value",
      },
    },
  ];

  const id = props.id;
  useEffect(() => {
  });

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const onClickBulkHandler = async () => {
    setShowBulkValidationModal(true);
  };

  const onClickQueryandler = async () => {
    setShowIntermediateQueryValidationModal(true);
  };

  const onClickAddConfigHandler = async () => {
    setShowAddConfigModal(true);
  };

  const toogleRenderAgGrid = () => {
    setRenderAgGrid((previousValue) => !previousValue);
  };

  const getQueryAttributes = (attributes) => {
    // attribute.attribute_name === "derived_tables_queries_loc" || attribute.attribute_name === "intermediate_queries"
    let attributes_payload = attributes.data.filter(attribute => (attribute.attribute_name==="intermediate_queries" || attribute.attribute_name==="intermediate_queries_loc")).map(attribute=>{
      return { 
        attribute_value : attribute.attribute_value,
        attribute_name : attribute.attribute_name}
    }
    )
    setQueryPayload(attributes_payload)
  }


  const saveNewMappingRowAdded = async (data) => {
    // setRenderAgGrid(false);
    // props.setUserMgmtLoader(true);
    let body = {
      ...data,
    };
    try {
      let response = await props.createMappingTableConfig(body);
      if (response.status) {
        displaySnackMessages("New row added successfully", "success");
        setShowAddConfigModal(false);
      } else displaySnackMessages(response.message, "error");
    } catch (err) {
      if (!isEmpty(err.response.data?.message)) {
        displaySnackMessages(err.response.data?.message, "error");
      } else {
        displaySnackMessages("Something went wrong", "error");
      }
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <Header
          id={id}
          configurationTab={props.configurationTab}
          onClickAddConfigHandler={onClickAddConfigHandler}
          onClickQueryandler={onClickQueryandler}
          onClickBulkHandler={onClickBulkHandler}
          userRole={userScreenLevelAccess}
        />
        {props.configurationTab === "data_ingestion_configuration"
          ? renderAgGrid && (
              <DataIngestionConfigTable
                renderTable={toogleRenderAgGrid}
                id={id}
                location={props.location}
                getQueryAttributes={getQueryAttributes}
                userRole={userScreenLevelAccess}
              />
            )
          : !showAddConfigModal && (renderMappingAgGrid && <SourceMappingConfigTable id={id} setRenderMappingAgGrid={setRenderMappingAgGrid}/>)}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {showAddConfigModal && (
            <AddSourceMappingConfig
              newFormFields={newFormFields}
              onApply={(data) => saveNewMappingRowAdded(data)}
              handleClose={() => setShowAddConfigModal(false)}
              configurationTab={props.configurationTab}
            />
          )}
          
          {(showBulkValidationModal || showIntermediateQueryValidationModal) && (
            <BulkValidation
              handleClose={() => showIntermediateQueryValidationModal ? setShowIntermediateQueryValidationModal(false) : setShowBulkValidationModal(false)}
              dataUrl={showIntermediateQueryValidationModal ? DATAPLATFORM_SOURCING_QUERY_VALIDATION : DATAPLATFORM_SOURCING_BULK_VALIDATION}
              showIntermediateQueryValidationModal={showIntermediateQueryValidationModal}
              queryPayload={queryPayload}
            />
          )}
        </div>
      </Container>
    </>
  );
};

Configuration.propTypes = {
  id: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};
const mapActionsToProps = {
  addSnack,
  createMappingTableConfig,
  getModuleLevelAccess
};

export default connect(mapStateToProps, mapActionsToProps)(Configuration);
