// import { attributeFormatter } from "modules/assortsmart/utils-assortsmart/utilityFunctions";
import { levelsArray } from "./constants";

import {
  percentFormatter,
  numbersWithComma,
  dollarFormatter,
  decimalsFormatter,
  arrayToCommaFormatter,
  formattedDate,
} from "../formatter";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, MAX_VALUE, MIN_VALUE } from "config/constants";

const attributeFormatter = function (value) {
  //isPrice key is used for inserting dollar in value formatter

  //If value is not a valid type, return empty string
  if (!value) {
    return "";
  }

  //If value is not a numerical type, return by camel casing the text
  let letter = /[a-zA-Z]/g;
  if (value.match(letter)) {
    return value.length < 4
      ? value
          .replace(" penetration", "")
          .split("_")
          .map((setAllfield) => {
            //If setAll field contains term "cc",e.g max_cc whole term needs to be converted to uppercase
            if (setAllfield === "cc" || setAllfield === "aur")
              return setAllfield.toUpperCase();
            return (
              setAllfield.charAt(0).toUpperCase() +
              setAllfield.slice(1).toUpperCase() +
              " "
            );
          })
          .join("")
      : value
          .replace(" penetration", "")
          .split("_")
          .map((setAllfield) => {
            //If setAll field contains term "cc",e.g max_cc whole term needs to be converted to uppercase
            if (setAllfield === "cc" || setAllfield === "aur")
              return setAllfield.toUpperCase() + " ";
            return (
              setAllfield.charAt(0).toUpperCase() + setAllfield.slice(1) + " "
            );
          })
          .join("");
  }

  //If value is in numerical format
  return value
    .replace(" penetration", "")
    .replace("_", " ")
    .split("_")
    .map((e) => {
      let temp = e.split(" ");
      temp[0] = parseFloat(temp[0]) >= 0 ? parseInt(temp[0]).toString() : "";
      temp[1] = parseFloat(temp[1]) >= 0 ? parseInt(temp[1]).toString() : "";
      return temp[1].length ? temp[0] + " - " + temp[1] : temp[0];
    })
    .join("");
};

const getDateFormat = (column) => {
  //getDateFormat function would return the format for the date column
  //It will first check if the format is present from the dateFormatter key
  //Else it will check with the formatter key
  //If not default date format will be passed to the momment format
  if (column?.dateFormatter) {
    return column?.dateFormatter;
  }
  if (column?.formatter) {
    return column?.formatter;
  }
  return DEFAULT_DATE_FORMAT;
};
export const nonEditableCell = (item, forFooter) => {
  //it returns the formatter to cell based on type
  const isDecimal = item.formatter === "roundOfftoTwoDecimals" ? true : false;
  const isRoundedOfftoThreeDecimals =
    item.formatter === "roundOfftoThreeDecimals" ? true : false;
  switch (item.type) {
    case "percentage":
      return (ins) =>
        percentFormatter(
          ins,
          isDecimal,
          (forFooter && item.is_editable) ||
            item?.multiplier ||
            item?.extra?.multiplier
            ? true
            : false //for footer we don't want to multiply it by 100 so passing true
        );
    case "int":
      return (ins) =>
        numbersWithComma(
          ins,
          isDecimal,
          isRoundedOfftoThreeDecimals,
          item?.extra?.disableCommaFormatting
        );
    case "float":
      return (ins) =>
        decimalsFormatter(ins, isDecimal, isRoundedOfftoThreeDecimals);
    case "dollar":
      return (ins) => dollarFormatter(ins, isDecimal);
    case "attribute":
      return (ins) => attributeFormatter(ins.value, false);
    case "bool":
      return (ins) => ins?.value || "";
    case "array":
      return (ins) => arrayToCommaFormatter(ins.value);
    case "date":
    case "DateTimeField":
      return (ins) => formattedDate(ins.value, getDateFormat(item));
    default:
      return (ins) => {
        // Adding numbersWithComma formatter for str type columns
        if (item.formatter && item.formatter === "numbersWithComma") {
          return numbersWithComma(ins, isDecimal);
        } else if (ins.value === 0 || ins.value === false || ins.value) {
          return fetchDefaultValue(ins);
        }
        return "";
      };
  }
};

const fetchDefaultValue = (ins) => {
  // explicitly converting to string to get comma separated values in aggrid table.
  if (Array.isArray(ins.value)) return ins.value.toString();
  else if (typeof ins.value === "boolean") return ins.value.toString();
  // to return true or false
  else return ins.value;
};

export const generateHeader = (item, levelsJson) => {
  //For levels column label will be dynamic so fetching it from levels api - eg :- cluster break down component
  if (levelsArray.includes(item.column_name)) {
    return levelsJson?.[item?.column_name] || item.label;
  } else {
    if (item.label === "LY") {
      return levelsJson?.[item.label] || item.label;
    }
    return item.label;
  }
};

/**
 *
 * @param {object} properties
 * @param {table instance} tableRef
 * @returns updated table instance
 * This function appends properties to table instance
 * grid api
 * We need to pass all the properties as an object
 * key - value would be inserted in similar way to grid
 */
export const appendPropertiesToTableInstance = (properties, tableRef) => {
  for (const propertyKey in properties) {
    tableRef.current.api[propertyKey] = properties[propertyKey];
  }
  return tableRef;
};

/**
 *
 * @param {string - unique Identifier} filterKey
 * @param {FilterObject} filterModel
 * @returns rangeBody payload array
 * This functions takes in filterKey and filterModel as arguments and loops in through filterModel
 * to check the type of filter applied ex: equals, lessThanOrEqual, greaterThanOrEqual, InRange
 * Respectively, the rangeBody is prepared, for minVal and maxVal we have used constant values
 */
export const parseRangeBody = (filterKey, filterModel) => {
  const filterType = filterModel[filterKey].type;
  let toRangeKey = [];
  switch (filterType) {
    case "greaterThanOrEqual":
      toRangeKey.push({
        column: filterKey,
        min_val: filterModel[filterKey].filter,
        max_val: MAX_VALUE,
        search_type: filterType,
      });
      break;
    case "lessThanOrEqual":
      toRangeKey.push({
        column: filterKey,
        min_val: MIN_VALUE,
        max_val: filterModel[filterKey].filter,
        search_type: filterType,
      });
      break;
    case "inRange":
      toRangeKey.push({
        column: filterKey,
        min_val: filterModel[filterKey].filter,
        max_val: filterModel[filterKey].filterTo,
        search_type: filterType,
      });
      break;
    default:
      toRangeKey.push({
        column: filterKey,
        min_val: filterModel[filterKey].filter,
        max_val: filterModel[filterKey].filter,
        search_type: filterType,
      });
      break;
  }
  return toRangeKey;
};

/**
 *
 * @param {any} item_1
 * @param {any} item_2
 * @returns int
 * This function compares two items in relationally and returns
 * the corresponding integer based on the condition
 */
export const customCompare = (item_1, item_2) => {
  if (+item_1 > +item_2) {
    return 1;
  } else if (+item_1 < +item_2) {
    return -1;
  } else {
    return 0;
  }
};

/**
 *
 * @param {object} params // table instance
 * @returns total width occupied by the visible columns
 */
export const getAllColumnsWidth = (params) => {
  // calculating total width all columns are occupying
  let allColumnsWidth = 0;
  params.columnApi.getAllColumns().forEach((column) => {
    if (column.visible) {
      allColumnsWidth += column.actualWidth;
    }
  });

  return allColumnsWidth;
};

/**
 *
 * @param {object} params // table instance
 * @returns grid width
 */
export const getGridWidth = (params) => {
  const clientWidth = params.clientWidth
    ? params.clientWidth
    : params.api.columnModel.gridApi.gridBodyCtrl.eBodyViewport.clientWidth;

  return clientWidth;
};

export const autoSizeGridColumns = (params) => {
  params.columnApi.autoSizeAllColumns();
  params.columnApi.getAllColumns().forEach((column) => {
    if (column.userProvidedColDef?.extra?.width) {
      params.columnApi.setColumnWidth(
        column.colId,
        column.userProvidedColDef.extra.width
      );
    }
  });
};
